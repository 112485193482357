import { useState } from "react";
import { Translate } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.css";

function Header() {
  const [language, setLanguage] = useState("es");
  const [t, i18n] = useTranslation("common");

  const changeLanguageHandler = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <header className="d-flex justify-content-between pt-5 px-4 px-md-0">
      <svg width="137" height="30" viewBox="0 0 137 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.3145 14.3127C74.3145 7.8412 82.6761 2.97396 88.6212 8.13835C93.7001 12.5499 92.1235 23.0062 82.447 23.0062C77.8692 23.0062 74.3145 18.9564 74.3145 14.3127ZM130.401 22.4455V28.3346H120.305V22.4455H130.121C130.121 16.7407 131.018 11.5144 127.958 8.34348C123.158 3.36983 114.416 6.16028 114.416 14.5935V28.3346H108.527V13.1912C108.527 6.4011 114.657 0.0108583 121.427 0.0108583C124.468 0.0108583 125.639 -0.0542666 128.519 1.3324C132.128 3.07109 136.01 7.85688 136.01 12.9109V22.4455H130.401ZM99.834 28.3346H105.723V0.851881H99.834V28.3346ZM30.5669 28.3346H36.456V0.851881H30.5669V28.3346ZM38.9799 13.1912V28.3346H44.8691V14.0324C44.8691 3.3311 60.5732 3.46648 60.5732 13.7519V28.3346H66.4622V12.3499C66.4622 5.70552 59.5043 -0.0981099 52.8703 0.00125755C45.8826 0.106225 38.9799 5.32438 38.9799 13.1912ZM5.88908 6.46078H0V16.5564H5.88908V6.74096C11.5937 6.74096 16.82 5.84329 19.9911 8.90384C24.9647 13.7042 22.1741 22.4455 13.741 22.4455H0V28.3346H15.1434C21.9333 28.3346 28.3236 22.205 28.3236 15.4346C28.3236 12.3942 28.3888 11.2224 27.002 8.343C25.2635 4.73409 20.4777 0.851881 15.4236 0.851881H5.88908V6.46078ZM68.4254 13.7519C68.4254 18.7423 69.3197 21.5259 72.8379 25.0434C81.7709 33.9758 97.5903 27.4484 97.5903 14.5932C97.5903 6.70624 91.4007 0.0105382 82.447 0.0105382C75.2125 0.0105382 68.4254 6.54815 68.4254 13.7519Z"
          fill="#012A5A"
        />
      </svg>
      <div className="d-flex gap-2 align-items-center">
        <Translate size={25} />
        <span
          className={`${styles.link} ${language === "es" ? styles.active : ""}`}
          onClick={() => {
            changeLanguageHandler("es");
          }}
        >
          ES
        </span>
        <div>|</div>
        <span
          onClick={() => {
            changeLanguageHandler("en");
          }}
          className={`${styles.link} ${language === "en" ? styles.active : ""}`}
        >
          EN
        </span>
      </div>
    </header>
  );
}

export default Header;
